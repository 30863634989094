import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgSwanLogo from '../img/logo_swan.jpg'

const Page = () => (
  <Layout pageName="women">
    <SEO title="Women" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article id="post-39" className="post-39 page type-page status-publish hentry">
          <div className="image-container"><img src={imgSwanLogo} /></div>
          <div className="text-flow">
            <p>As a Cosmopolitan writer recently confessed: “I consider myself a pretty honest person, yet for the past
              five years, I have kept a secret from nearly everyone I know. I get botox… I loved how it made me look. My
              forehead was suddenly smooth, yet I could still move my brows like a normal human. And although I saw a
              dramatic difference, no one else did. They never asked. I never told… Now, five years later, getting Botox
              has become a staple in my beauty maintenance routine — something as essential as keeping my buttery
              highlights bright.”</p>

            <p>More and more women are getting a little helping hand to keep their youthful looks: especially through
              botulinum toxin injections and dermal fillers to address wrinkles or scars. These treatments work and
              avoid the unnatural look of surgical interventions. And, if you are unfortunate enough to have excess
              underarm sweating, a few botulinum toxin injections into your skin can stop the problem for 3 to 6 months
              and boost your comfort and confidence. </p>

            <p>If you're interested in making your face look more youthful, but worried about who you are putting your
              faith in, <a href="/about-us">Dr Neggin</a> is the right person to help you. With her 15 years of surgical
              experience, working and teaching in hospitals and private practice often in the most minute spaces (mouth
              and face), you can trust in her skill and gentleness. And, if you have any nagging concerns about being
              spotted visiting an aesthetics centre, what could be more natural than a trip to the <a
                href="/contact">dentists</a>!</p>

            <p>You might consider the following treatments:</p>

            <ul className="list-dot list-indent">
              <li><a href="/treatments-and-prices/#botuminum-toxin-for-women">Botulinum Toxin</a> — or botox works by
                relaxing selected muscles that pull the skin causing lines and wrinkles. As the muscles relax, the lines
                will soften or even disappear completely.
              </li>
              <li><a href="/treatments-and-prices/#dermal-fillers">Dermal Fillers</a> — are designed to plump up static
                wrinkles and lines in your skin, softening them to give your skin a more smooth and youthful look.
                Dermal fillers can also be used to reduce the appearance of scarring.
              </li>
              <li><a href="/treatments-and-prices/#hyperhidrosis">Hyperhidrosis</a> — botox for excessive sweating. With
                this treatment, botox is injected into the skin to stop sweating for 3-6 months.
              </li>
            </ul>

            <p>It is important to note that all these treatments are temporary in nature (typically lasting 3-6 months).
              This is an advantage versus more invasive surgical procedures, where you may be left with the effects (for
              good and possibly bad) for a lifetime. But it is important to note that if you want to maintain the
              effects, regular treatment is required.</p>

            <p>Whichever treatment you might be interested in, the first step is a consultation with <a
              href="/about-us">Dr Neggin</a> in either of our two <a href="/contact">surgeries</a>.</p>
          </div>
        </article>
      </main>
    </div>
  </Layout>
)

export default Page